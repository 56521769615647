import React from "react"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import Pricing from "../components/Pricing/Pricing"

export default () => {
  return (
    <>
      <Breadcrumbs
        title="Pricing"
        description="Try OpenDatastage for free for 14 days. No credit card required."
      />
      <Pricing noTitle={true} />
    </>
  )
}
